import dayjs from "dayjs";

export type CalendarEvent = {
  start: string;
  end: string;
  summary: string;
  description: string;
  location: string;
};

export const createICalLink = (event: CalendarEvent) => {
  const data = [
    "BEGIN:VCALENDAR",
    "VERSION:2.0",
    "PRODID:-//3t.no//",
    "CALSCALE:GREGORIAN",
    "BEGIN:VTIMEZONE",
    "TZID:Europe/Oslo",
    "TZURL:https://www.tzurl.org/zoneinfo-outlook/Europe/Oslo",
    "X-LIC-LOCATION:Europe/Oslo",
    "BEGIN:DAYLIGHT",
    "TZNAME:CEST",
    "TZOFFSETFROM:+0100",
    "TZOFFSETTO:+0200",
    "DTSTART:19700329T020000",
    "RRULE:FREQ=YEARLY;BYMONTH=3;BYDAY=-1SU",
    "END:DAYLIGHT",
    "BEGIN:STANDARD",
    "TZNAME:CET",
    "TZOFFSETFROM:+0200",
    "TZOFFSETTO:+0100",
    "DTSTART:19701025T030000",
    "RRULE:FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU",
    "END:STANDARD",
    "END:VTIMEZONE",
    "BEGIN:VEVENT",
    "UID:" + dayjs().unix(),
    "DTSTART:" + dayjs(event.start).format("YYYYMMDDTHHmmss"),
    "DTEND:" + dayjs(event.end).format("YYYYMMDDTHHmmss"),
    "SUMMARY:" + event.summary,
    "DESCRIPTION:" + event.description,
    "LOCATION:" + event.location,
    "END:VEVENT",
    "END:VCALENDAR"
  ].join("\n");

  const blob = new Blob([data], { type: "text/calendar;charset=utf-8" });
  const url = URL.createObjectURL(blob);
  return url;
};

export const getGoogleCalendarLink = (event: CalendarEvent) => {
  return `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${dayjs(event.start).format(
    "YYYYMMDDTHHmmssZ"
  )}%2F${dayjs(event.end).format("YYYYMMDDTHHmmssZ")}&details=${event.description}&location=${event.location}&text=${
    event.summary
  }`;
};
