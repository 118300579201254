import { DragEventHandler } from "react";
import ContentLoader from "react-content-loader";

import { classNames, ResponsiveBreakpoints } from "@/utils/styling-utils";
import { Text } from "@/components/ui/text";
import { ImageProps as NextImageProps } from "next/dist/shared/lib/get-img-props";
import { getImageProps } from "next/image";

interface ImageProps {
  alt: string;
  caption?: string;
  gradient?: boolean;
  onDragStart?: DragEventHandler<HTMLImageElement>;
  priority?: boolean;
  quality?: number;
  src: string;
  srcDesktop?: string;
  placeholder?: string;
}

export const Image = ({
  alt,
  caption,
  gradient,
  onDragStart,
  priority,
  quality = process.env.NEXT_PUBLIC_DEFAULT_IMAGE_QUALITY ? +process.env.NEXT_PUBLIC_DEFAULT_IMAGE_QUALITY : 75,
  src,
  srcDesktop,
  placeholder
}: ImageProps) => (
  <div className={classNames("image", gradient && "image--gradient")}>
    <NextJsImageWrapper
      alt={alt}
      onDragStart={onDragStart}
      quality={quality}
      priority={priority}
      src={src}
      srcDesktop={srcDesktop}
      //TODO finn en mindre hacky løsning. Placeholder/blur oppsettet takler bilder med transparency dårlig. Vi sjekker om
      //urlen inneholder png eller svg og bruker da ikke placeholder
      placeholder={src.indexOf("png") !== -1 || src.indexOf("svg") !== -1 ? undefined : placeholder}
    />
    {caption && <Text color="gray" size="small" value={caption} />}
  </div>
);

// The Next.js Image component does not natively support serving different images based on viewport size (e.g., desktop vs. mobile).
// Next.js documentation suggests an approach for implementing art direction with images:
// https://nextjs.org/docs/pages/api-reference/components/image#art-direction
function NextJsImageWrapper({ alt, onDragStart, priority, quality = 100, src, srcDesktop, placeholder }: ImageProps) {
  const commonImageProps: NextImageProps = {
    alt: alt,
    blurDataURL: placeholder ?? undefined,
    fill: true,
    onDragStart: onDragStart,
    placeholder: placeholder ? "blur" : undefined,
    priority: priority,
    quality: quality,
    sizes: "100%",
    src: src
  };

  const {
    props: { srcSet: desktop }
  } = getImageProps({
    ...commonImageProps,
    src: srcDesktop ?? src
  });

  const {
    props: { ...mobile }
  } = getImageProps({
    ...commonImageProps
  });

  return (
    <picture>
      <source media={`(min-width: ${ResponsiveBreakpoints.LARGE}px)`} srcSet={desktop} />
      <img {...mobile} alt={alt} />
    </picture>
  );
}

export const ImageSkeleton = () => (
  <ContentLoader viewBox="0 0 500 400" height={300} backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
    <path d="M484.52,64.61H15.65C7.1,64.61.17,71.2.17,79.31V299.82c0,8.12,6.93,14.7,15.48,14.7H484.52c8.55,0,15.48-6.58,15.48-14.7V79.31C500,71.2,493.07,64.61,484.52,64.61Zm-9,204.34c0,11.84-7.14,21.44-15.94,21.44H436.39L359.16,171.52c-7.1-10.92-19.67-11.16-27-.51L258.64,277.94C253.78,285,245.73,286,240,280.2l-79.75-80.62c-6-6.06-14.33-5.7-20,.88L62.34,290.39H40.63c-8.8,0-15.94-9.6-15.94-21.44V110.19c0-11.84,7.14-21.44,15.94-21.44H459.54c8.8,0,15.94,9.6,15.94,21.44Z" />
    <ellipse cx="120" cy="140" rx="28" ry="28" />
  </ContentLoader>
);
