import { ReactNode } from "react";

import { classNames } from "@/utils/styling-utils";

interface IconButtonProps {
  ariaLabel: string;
  buttonType?: "primary";
  children: ReactNode;
  onClick: () => void;
}

export const IconButton = ({ ariaLabel, buttonType, children, onClick }: IconButtonProps) => (
  <button
    aria-label={ariaLabel}
    className={classNames("icon-button", buttonType === "primary" && "icon-button--primary")}
    onClick={onClick}
    title={ariaLabel}
  >
    {children}
  </button>
);
